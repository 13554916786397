import React from "react";
import { Col, Row } from "antd";

class ContentAppointments extends React.Component {
  render() {
    return (
      <div className="slide-content">
        <Row>
          <Col xs={24} lg={12}>
            <h2>Appointments</h2>
            <p>
              COMING SOON - an efficient way to take appointment bookings from
              your clients for your time or resources.
            </p>
            <p>
              Easy to set and use for any service that requires appointment
              bookings.
            </p>
            <p>
              Giving your clients an easy way to book appointments is a great
              convenience for both you and your client.
            </p>
          </Col>
          <Col xs={24} lg={12}>
            <img
              src="https://images.pexels.com/photos/1270948/pexels-photo-1270948.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
              className="homepage-image"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContentAppointments;
